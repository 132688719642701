import './App.css';
import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import { inscriptionReducer } from './reducers/inscriptionReducer';
import { useReducer } from 'react';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AppRouter from './AppRouter';
import { AppContext } from './context/AppContext';
import { useFetch } from './hooks/useFetch';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import MaintenanceScreen from './screens/MaintenanceScreen';

const init = () => {
  return localStorage.getItem('inscripciones') ? JSON.parse(localStorage.getItem('inscripciones')) : [];
};

const getSelectedLanguage = () => {
  return localStorage.getItem('ins_lang') ? localStorage.getItem('ins_lang') : 'es';
}
function App( props ) {

  const urlServerApi = "https://api.inscriu-t.com";
  // Creación del Language para el context...
  const fetchLanguages = useFetch(`${ urlServerApi }/languages`);
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    const lang = getSelectedLanguage();
    if (lang !== i18n.language){
      i18next.changeLanguage(lang);
    }
  }, [fetchLanguages,i18n.language]);

  const { data: dataLanguages } = !fetchLanguages.loading && fetchLanguages;
  const languages = !!dataLanguages && dataLanguages[0];
  const language = i18n.language;
  
  const [inscripciones, dispatch ] = useReducer(inscriptionReducer, [], init);
  useEffect( () =>{
    localStorage.setItem('inscripciones', JSON.stringify(inscripciones));
  }, [inscripciones]);

  const OnCreateInscription = (formData) =>{
    console.log('Create Inscription!!', formData, uuidv4());
    const newInscription = {
      guid: uuidv4(),
      data: formData.data
    }
    const action = {
        type:'create',
        payload: newInscription
    };
    dispatch(action);
  };

  const OnDeleteInscription = (inscription) => {
    console.log('Delete Inscription!!', inscription);
    const action = {
        type:'delete',
        payload: inscription
    };
    dispatch(action);
  };
  return (
    <MaintenanceScreen />
  )/*
  return (
    <AppContext.Provider value={ {
      urlServerApi,
      language,
      languages,
      t,
      inscripciones,
      OnDeleteInscription,
      OnCreateInscription
    } }>
      <AppRouter />
    </AppContext.Provider>
  )*/
}

export default App;
