import { useEffect, useRef, useState } from "react";


export const useFetch = ( url ) => {

    const isMounted = useRef(true);

    const [state, setState] = useState({
        data: [],
        loading: true,
        error : null,
    });

    useEffect( () => {
        return () => {
                isMounted.current = false;
            }
    }, []);

    useEffect( () => 
    {
        fetch ( url )
            .then ( resp => resp.json())
            .then ( ({ data }) => {
                if (isMounted.current){
                    setState({
                        ...state,
                        loading: false,
                        error: null,
                        data
                    });
                }
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ url ]);

    return state;
};