import '../styles/FormFieldIT.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

function FormFieldIT({  languagei18,
                        guid, 
                        type, 
                        title, 
                        description, 
                        placeholder, 
                        value , 
                        changedValue,
                        requiredConfirmation = true }) {

  const inputValue = useRef();
  const inputConfirmationValue = useRef();
  const [ok, setOk] = useState(true);
  const handleChangeValue = (e) => {
    e.preventDefault();
    if (requiredConfirmation)
    {
      if (inputValue.current.value !== inputConfirmationValue.current.value) {
        setOk(false);
      } else {
        setOk(true);
      }
    }
    changedValue(e,
                inputValue,
                inputConfirmationValue);
  };


  if (type === 'text'){   
    return (   
      <>
        <div className="wizard-form-input clearfix  animate__animated animate__fadeInDown">
            <label className="lbl-title">{ title }</label>
            <p><small>{ description }</small></p>
            <input className="input-field" type= { type.name } 
                   name={ guid } 
                   placeholder={ placeholder }
                   value= { value ? value : '' }
                   onChange= { handleChangeValue }
                   ref={ inputValue }/>
        </div>
        
        {
          requiredConfirmation && 
            <div className="wizard-form-input clearfix  animate__animated animate__fadeInDown">
              <label className="lbl-title">{languagei18('confirm.label')} { title }</label>
              <p><small>{ description }</small></p>
              <input className="input-field" type= { type.name } 
                    name= { guid + "_validation" } 
                    placeholder={ languagei18('confirm.label') + " " + placeholder }
                    onChange= { handleChangeValue }
                    ref={ inputConfirmationValue }/>
            </div>
        }
        {
          (requiredConfirmation && !ok) && 
          <div className="wizard-form-input clearfix  animate__animated animate__fadeInDown">
            <span className="alert alert-danger">{ languagei18('confirm.label.error') }</span>
          </div>
        }
      </>
    );
  } else {
    return (
        <label>Datos Incorrectos</label>
    );
  }
}

FormFieldIT.propTypes  = {
  guid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  changedValue: PropTypes.func.isRequired,
};

FormFieldIT.defaultProps = {
  language: 'es'
};


export default FormFieldIT;
