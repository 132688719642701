import '../styles/FormFieldIT.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';

function FormNavigationButtons({ handleNext , handleBack,handleSubmit,  index, length , languagei18}) {

    
    if ( index === 0){
        return (
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <span className="btn btn-success float-right m-2" title={languagei18('next.button.label')} type="button" onClick={ () => handleNext() }> {languagei18('next.button.label')} <i className="fa fa-arrow-right"></i></span>
                  </div>
                </div>
              </div>
        );
    } else if (index >= length - 1){
        return (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <button className="btn btn-success float-right m-2" title={languagei18('finish.button.label')} type="submit">{languagei18('finish.button.label')} </button>
                <span className="btn btn-success float-right m-2" title={languagei18('back.button.label')}  type="button" onClick={ () => handleBack() }><i className="fa fa-arrow-left"></i> {languagei18('back.button.label')} </span>
              </div>
            </div>
          </div>
        );
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <span className="btn btn-success float-right m-2" title={languagei18('back.button.label')} type="button" onClick={ () => handleBack() }><i className="fa fa-arrow-left"></i> {languagei18('back.button.label')} </span>
            <span className="btn btn-success float-right m-2x" title={languagei18('next.button.label')} type="button" onClick={ () => handleNext() }>{languagei18('next.button.label')} <i className="fa fa-arrow-right"></i></span>
          </div>
        </div>
      </div>

    );

}


FormNavigationButtons.propTypes  = {
    handleNext: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    languagei18: PropTypes.func.isRequired,
};


export default FormNavigationButtons;
