import React, { useContext } from 'react';
import { AppContext } from '../context/AppContext';


function About(props) {
    const { t } = useContext(AppContext);
    return(
        <>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row row--35 align-items-center">

                        <div className="col-lg-5 col-md-12">
                            <div className="thumbnail">
                                <img className="w-100" src="/assets/images/about/about-1.jpeg" alt={t('language.about.altImage')}/>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">{ t('language.about.title') }</h2>
                                    <p className="description">{ t('language.about.description') }</p>
                                </div>
                                <div className="row mt--30 mt_sm--10">
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="about-us-list">
                                            <h3 className="title">{ t('language.about.who.1.title') }</h3>
                                            <p>{ t('language.about.who.1.description') }</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="about-us-list">
                                            <h3 className="title">{ t('language.about.who.2.title') }</h3>
                                            <p>{ t('language.about.who.2.description') }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About;