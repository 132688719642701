import { useState } from "react";


export const useCounter = ( initialState = 10, min = 0, max = 100 )  => {
    const [state, setState] = useState(initialState);

    const inc = ( factor = 1) => {
        setState(state + factor >= max ? max :state + factor);
    }

    const dec = ( factor = 1) => {
        setState(state - factor <= min ? state - factor : min);
    }

    const reset = () => {
        setState(initialState);
    }
    return {
        state, 
        inc, 
        dec,
        reset
    }
}

export default useCounter;