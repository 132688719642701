
export const inscriptionReducer = (state = [] , action) => {

    switch (action?.type) {
        case 'create':
            return [ ...state, action.payload];
        case 'delete':
            return state.filter( inscription => inscription.guid !== action.payload.guid);
        default:
            return state;
    }
};