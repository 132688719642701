import React, { useContext } from 'react';
import { AppContext } from '../context/AppContext';


function HomeServicios() {

    const { t } = useContext(AppContext);

    const ServiceList = [
        {
            icon: t('language.home.service.1.ico'),
            title: t('language.home.service.1.title'),
            description: t('language.home.service.1.description')
        },
        {
            icon: t('language.home.service.2.ico'),
            title: t('language.home.service.2.title'),
            description: t('language.home.service.2.description')
        },
        {
            icon: t('language.home.service.3.ico'),
            title: t('language.home.service.3.title'),
            description: t('language.home.service.3.description')
        },
    ];
     
    return(
        <>
            <div className="row">
                {ServiceList.map( (val , i) => (
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                        <div className="service service__style--1">
                            <div className="icon">
                                <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Incriu-T"/>
                            </div>
                            <div className="content">
                                <h4 className="title">{val.title}</h4>
                                <p>{val.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default HomeServicios;