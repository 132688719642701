import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import AboutScreen from './screens/AboutScreen';
import HomeScreen from './screens/HomeScreen';
import InscripcionScreen from './screens/InscripcionScreen';
import LoginScreen from './screens/LoginScreen';

import NavBarComponent from './components/NavBarComponent';


function AppRouter( { inscripciones, OnDeleteInscription }) {

    return (
        <Router>
            <div>
                <NavBarComponent /> 
                <Switch>
                    <Route exact path="/about" component={ AboutScreen }/>
                    <Route exact path="/login" component={ LoginScreen }/>
                    <Route exact path="/inscripcion" component={ InscripcionScreen }/>
                    <Route exact path="/" component={ HomeScreen }/>
                    <Redirect to="/"/>
                </Switch>
            </div>
        </Router>
    );
}

export default AppRouter;