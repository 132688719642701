import '../styles/FormComponentIT.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import FormFieldIT from './FormFieldIT';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import FormNavigationButtons from './FormNavigationButtons';
import { useForm } from '../hooks/useForm';
import useCounter from '../hooks/useCounter';
import { useFetch } from '../hooks/useFetch';
import { AppContext } from '../context/AppContext';


function FormComponentIT({ guidForm, language, handleSubmitEvent }) {
  const { t, urlServerApi } = useContext(AppContext);

  const fetchForm = useFetch(`${ urlServerApi }/forms/${guidForm}`);
  const { data: dataForm } = !fetchForm.loading && fetchForm;
  const form = !!dataForm && dataForm[0];


  const { state: indexSeleccionado, inc, dec } = useCounter(0, 0, form.sections ? form.sections.length : 0);
  const [ formObject, 
          initValuesForm, 
          handleSubmit, 
          formInputValueChange] = useForm({}, handleSubmitEvent );
  let seccion = form.sections ? form.sections[indexSeleccionado] : undefined;

  useEffect(() =>{
    if (form.sections) {
      let fieldsMap = {};
      form.sections.map( s => {
        return s.fields.map ( f =>{
          return fieldsMap[f.guid] = '';
        })
      });
      initValuesForm(fieldsMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ form ]);
  
  if (!form){
    return (
      <div className="container">
        <div className="row">
          <div className="col-6 offset-3 alert alert-warning">Loading...</div>
        </div>
      </div>
    );
  }

  return ( 
    
      <div className="row h-100 form-component-ins">
        <div className="col-md-12 col-lg-3 steps">
          <div className="multisteps-form__progress">
            {
              form.sections &&
              form.sections.map ( (e,i) =>{  
                return <span key={ e.guid } 
                              className= { i === indexSeleccionado ? 'multisteps-form__progress-btn js-active' : 'multisteps-form__progress-btn'} 
                              title={ e.translations.find( (t) => t.language === language ).title }>
                                <i className={ e.icon } ></i>
                                <span >{ e.translations.find( (t) => t.language === language ).title} </span></span>
              })
            }
          </div>
        </div>
        <div className="col-md-12 col-lg-9">
          <form className="multisteps-form__form" action="#" id="wizard" onSubmit= { handleSubmit }>
            <div className="container clearfix">
              <div className="row">
                <div className="col-sm-12 form-area">
                  <div className="js-active" data-animation="slideHorz">
                    <div className="wizard-forms">

                      <div className="">
                        
                        <div className="wizard-title mt-5 text-center">
                            <h3>{ seccion && seccion.translations.find( (t) => t.language === language ).title_description }</h3>
                            <p> { seccion && seccion.translations.find( (t) => t.language === language ).subtitle_description } </p>
                        </div>
                        <div className="wizard-form-field mb-85 clearfix">
                          {
                              seccion &&
                              seccion.fields.map( (f, i) => {
                                const type  = f.fieldType;
                                const valor = formObject.data[f.guid] === undefined ? ( type === 'text' ? '' : undefined) : formObject.data[f.guid];
                                const { title, description, placeholder} = f.translations.find(t => t.language === language) ?
                                                                          f.translations.find(t => t.language === language) : 
                                                                          ['','',''];
                                return <FormFieldIT 
                                        languagei18 = { t }
                                        key={ f.guid } 
                                        guid= { f.guid }
                                        type={ type.name } 
                                        language={ language }
                                        title= { title }
                                        description= { description }
                                        placeholder = { placeholder }
                                        value= { valor }
                                        requiredConfirmation = { f.confirmation === 1} 
                                        changedValue = { formInputValueChange } />;
                              })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {
                form &&
                form.sections &&
                <FormNavigationButtons 
                  handleNext =  { inc }
                  handleBack =  { dec }
                  handleSubmit =  { handleSubmit }
                  index =  { indexSeleccionado }
                  length =  { form.sections.length }
                  languagei18 = { t }
                />
              }
            </div>
          </form>
        </div>
      </div>
    
  );

}

FormComponentIT.propTypes  = {
  language: PropTypes.string.isRequired,
};

FormComponentIT.defaultProps = {
  language: 'es'
};


export default FormComponentIT;
