import {  useState } from "react";


export const useForm = ( initialState = {}, handleSubmitEvent  ) => {
    const [values, setValues] = useState({ data: initialState, error: null, valid: {}});

    const initValues = (newState = {}) =>{
        setValues( c => ({ ...c , data: newState, valid: initValidValues(newState)}));
    }

    const initValidValues = (newState) => {
        let validArray = {};
        Object.keys(newState).map((key, index) => {
                validArray[key] = false;
                return true;
            }
        );
        console.log(validArray);
        return validArray;
    };
    const changeInputValue = ({ target }, value = undefined, valueConfirmation = undefined) => {
        const newData = {...values.data, [target.name]: target.value};
        setValues( c => { 
            return { data: newData, error: c.error, valid: c.valid}
        });
    }

    const handleSubmit = (e) => {
      e.preventDefault();
      console.log('Submit:', values);
      handleSubmitEvent(values);
    }

    return [ values ,initValues,handleSubmit, changeInputValue];
};