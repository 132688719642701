import React, { useContext } from 'react';
import {FaTwitter ,FaInstagram ,FaFacebookF } from "react-icons/fa";
import { AppContext } from '../context/AppContext';


const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

const contactEmails = [
    { key: 'emailsoporte1', email: "soporte@inscriu-t.com", label: "soporte@inscriu-t.com"},
    { key: 'emailsoporte2', email: "info@cronorunner.com", label: "info@cronorunner.com"},
]
function Footer() {
    const { t } = useContext(AppContext);

    return(
        <>
            <footer className="footer-area">
                <div className="footer-wrapper">
                    <div className="row align-items-end row--0">
                        <div className="col-lg-6">
                            <div className="footer-left">
                                <div className="inner">
                                    <span>{ t('language.footer.ready') }</span>
                                    <h2>{ t('language.footer.ready2') }</h2>
                                    <a className="rn-button-style--2 mb-3" href="/contact">
                                        <span>{ t('language.footer.contact-us') }</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="footer-right" data-black-overlay="6">
                                <div className="row">
                                    {/* Start Single Widget */}

                                    <div className="col-lg-6 col-sm-6 col-12">
                                        <div className="footer-link">
                                            <h4>{ t("language.footer.quick.link") }</h4>
                                            <ul className="ft-link">
                                                <li><a href="/inscripciones">{ t("language.footer.quick.link.1") }</a></li>
                                                <li><a href="/about">{ t("language.footer.quick.link.2") }</a></li>
                                                <li><a href="/contact">{ t("language.footer.quick.link.3") }</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* End Single Widget  */}
                                    {/* Start Single Widget  */}
                                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                        <div className="footer-link">
                                            <h4>{ t("language.footer.contact.label") }</h4>
                                            <ul className="ft-link">
                                                { contactEmails.map( c => <li key={c.key}><a href={`mailto:${c.email}`}>{ c.label }</a></li> )}
                                            </ul>

                                            <div className="social-share-inner">
                                                <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                    {SocialShare.map((val , i) => (
                                                        <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                    ))}
                                                </ul>
                                            </div> 
                                        </div>
                                    </div>
                                    {/* End Single Widget  */}

                                    <div className="col-lg-12">
                                        <div className="copyright-text">
                                            <p>Copyright © 2021 Cronorunner S.L. All Rights Reserved.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;